<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-data-table
      :headers="headers"
      :items="listProducts"
      :loading="isLoadingProducts"
      :server-items-length="productsMeta.totalItems"
      :options.sync="options"
      :items-per-page="30"
      :footer-props="{ 'items-per-page-options': [5, 30, 50] }"
    >
      <template v-slot:item.image="{ item }">
        <v-img
          v-if="item.image"
          :src="item.image.url"
          max-height="50"
          max-width="50"
        />
        <v-img
          v-else
          src="@/assets/product.png"
          max-height="50"
          max-width="50"
        />
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          :color="getColor(item.status)"
          dark
        >
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="$admin.can('alert-view')"
          small
          class="mr-2"
          @click="showItem(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
          v-if="$admin.can('alert-update')"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("products.products") }}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <edit-products v-if="$admin.can('alert-update')"></edit-products>
          <show-product v-if="$admin.can('alert-view')"></show-product>
          <upload-alerts v-if="$admin.can('alert-import')"></upload-alerts>
        </v-toolbar>
        <v-row>
          <v-col
            cols="12"
            sm="3"
            md="3"
          >
            <v-text-field
              clearable
              dense
              hide-details
              outlined
              v-model="options.search"
              :label="$t('search')"
              class=""
            ></v-text-field>
          </v-col>
          <v-col
            class="px-0"
            cols="12"
            sm="3"
            md="3"
          >
            <v-autocomplete
              clearable
              dense
              hide-details
              outlined
              v-model="options.hub_id"
              :items="hubs"
              item-text="name"
              item-value="id"
              :label="$t('hub')"
              :search-input.sync="search_hub"
              :loading="isLoadingHubs"
              no-filter
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="3"
          >
            <v-autocomplete
              clearable
              dense
              hide-details
              outlined
              v-model="options.provider_id"
              :items="providers"
              item-text="name"
              item-value="id"
              :loading="isLoadingProviders"
              :label="$t('provider')"
              @focus="getProviders"
            ></v-autocomplete>
          </v-col>
          <v-col
            class="pl-0"
            cols="12"
            sm="3"
            md="3"
          >
            <v-autocomplete
              clearable
              dense
              hide-details
              outlined
              v-model="options.category_parent_id"
              :items="categories"
              item-text="name"
              item-value="id"
              :loading="isLoadingCategories"
              :label="$t('category')"
              @focus="getCategories"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import { helpers } from "@/mixins/helpers";

export default {
  mixins: [helpers],
  components: {
    ShowProduct: () => import("./product/Show.vue"),
    UploadAlerts: () => import("./product/ImportAlertsCSV"),
    EditProducts: () => import("./product/Edit.vue"),
  },

  computed: {
    ...mapGetters({
      isLoadingCategories: "categories/isLoadingCategories",
      isLoadingProviders: "providers/isLoadingProviders",
      isLoadingHubs: "hubs/isLoadingHubs",
      isLoadingSections: "sections/isLoadingSections",
      isLoadingAttributes: "attributes/isLoadingAttributes",

      hubs: "hubs/listHubs",
      providers: "providers/listProviders",
      stores: "stores/listStores",
      categories: "categories/listRoots",

      isLoadingProducts: "productAlerts/isLoading",
      listProducts: "productAlerts/getList",
      productsMeta: "productAlerts/meta",
      productsQueryParams: "productAlerts/params",
    }),

    options: {
      get() {
        return this.$store.state.productAlerts.params;
      },
      set(value) {
        this.$store.commit("productAlerts/SET_PARAMS", value);
      },
    },
  },

  watch: {
    options: {
      handler: debounce(async function () {
        if (this.isActive) {
          this.is_loading_product = true;
          await this.$store
            .dispatch("productAlerts/list", this.options)
            .then(() => {
              this.is_loading_product = false;
            });
        }
      }, 1000),
      deep: true,
    },

    search_hub: debounce(function (search) {
      if (this.isLoadingHubs) return;

      this.$store.dispatch("hubs/list", {
        store_id: this.store_id,
        itemsPerPage: 30,
        search,
      });
    }, 1000),
  },

  props: {
    isActive: {
      types: Boolean,
    },
  },

  data() {
    return {
      // options: { itemsPerPage: 30 },
      search_hub: "",
      is_loading_product: false,

      headers: [
        {
          text: this.$t("sku"),
          align: "start",
          sortable: false,
          value: "sku",
        },
        {
          text: this.$t("code"),
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: this.$t("image"),
          align: "start",
          value: "image",
          sortable: false,
        },
        {
          text: this.$t("name"),
          align: "start",
          value: "name",
        },

        {
          text: this.$t("created_at"),
          value: "created_at",
        },

        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    showItem(item) {
      if (this.categories === undefined || this.categories?.length == 0) {
        this.getCategories();
      }
      this.$store.dispatch("products/openShowForm", item);
    },

    editItem(item) {
      this.$store.dispatch("products/openEditForm", item);
    },

    getColor(status) {
      if (status === "active") return "green";
      else return "red";
    },
  },
};
</script>
